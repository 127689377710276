export interface IAppConfig {
  wrServerUrl: string;
  clientAppUrl: string;
  serviceUrl: string;
  authCallbackUrl: string;
}

function getConfig(): IAppConfig {
  const appUrl = window.location
    ? `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }`
    : globalThis.RUMI_CLIENT_APP_SERVER_URL ||
      process.env.REACT_CLIENT_APP_RUMI_APP_SERVER_URL ||
      "https://io-client.develop.waitroom.com";
  console.debug(`REACT_APP_RUMI_IO_SERVER_URL: ${process.env.REACT_APP_RUMI_IO_SERVER_URL}`);
  console.debug(`RUMI_APP_SERVER_URL: ${process.env.RUMI_APP_SERVER_URL}`);
  return {
    wrServerUrl:
      globalThis.RUMI_APP_SERVER_URL ??
      process.env.REACT_APP_RUMI_APP_SERVER_URL ??
      "https://wormhole.staging.waitroom.com/",
    serviceUrl:
      globalThis.RUMI_IO_SERVER_URL ??
      process.env.REACT_APP_RUMI_IO_SERVER_URL ??
      "https://io-server.develop.waitroom.com",
    clientAppUrl: appUrl,
    authCallbackUrl: `${appUrl}/callback`,
  };
}

export const config = () => getConfig();
